var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "v-form",
        {
          ref: "form",
          staticClass: "d-flex flex-column mb-4",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-between",
              on: { click: _vm.openFileBottomSheet },
            },
            [
              _c("span", { staticClass: "my-1" }, [
                _vm._v(_vm._s(_vm.$t("Select image / video"))),
              ]),
              _c("v-btn", { attrs: { color: "primary", rounded: "" } }, [
                _vm._v(_vm._s(_vm.$t("Choice"))),
              ]),
            ],
            1
          ),
          _vm.selectedFile == "IMAGE"
            ? _c(
                "div",
                {
                  staticClass: "imagePreviewWrapper",
                  attrs: { id: "previewContainer" },
                },
                [
                  _c("v-img", {
                    staticClass: "img-preview",
                    attrs: {
                      "lazy-src": require("@/assets/images/placeholder.png"),
                      src: _vm.previewImage,
                    },
                  }),
                  _vm.selectedFile == "VIDEO"
                    ? _c("vue-plyr", { ref: "plyr" }, [
                        _c(
                          "video",
                          {
                            attrs: {
                              controls: "",
                              playsinline: "",
                              "data-plyr-config": '{ "ratio": "1:1" }',
                              src: _vm.previewVideo,
                            },
                          },
                          [
                            _vm._v(
                              " Your browser does not support the video tag. "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c("span", { staticClass: "my-1 d-block" }, [
            _vm._v(_vm._s(_vm.$t("Title"))),
          ]),
          _c("v-text-field", {
            attrs: {
              outlined: "",
              "single-line": "",
              type: "text",
              rules: _vm.titleRules,
              required: "",
              counter: "100",
            },
            model: {
              value: _vm.title,
              callback: function ($$v) {
                _vm.title = $$v
              },
              expression: "title",
            },
          }),
          _c("span", { staticClass: "my-1 d-block" }, [
            _vm._v(_vm._s(_vm.$t("Category"))),
          ]),
          _c("v-select", {
            attrs: {
              label: _vm.$t("Choose"),
              outlined: "",
              required: "",
              "single-line": "",
              "small-chips": "",
              loading: _vm.categoryLoading,
              multiple: "",
              "return-object": "",
              "no-data-text": _vm.$t("No data"),
              items: _vm.categoryList,
              "item-text": "value",
              "item-value": "id",
              rules: [(v) => !!v],
            },
            model: {
              value: _vm.groups,
              callback: function ($$v) {
                _vm.groups = $$v
              },
              expression: "groups",
            },
          }),
          _c("span", { staticClass: "my-1 d-block" }, [
            _vm._v(_vm._s(_vm.$t("Description"))),
          ]),
          _c("v-textarea", {
            attrs: {
              outlined: "",
              type: "text",
              counter: "1000",
              rules: _vm.descriptionRules,
              required: "",
            },
            model: {
              value: _vm.description,
              callback: function ($$v) {
                _vm.description = $$v
              },
              expression: "description",
            },
          }),
          _c(
            "div",
            { staticClass: "mt-1 text-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "col-12",
                  attrs: {
                    color: "primary",
                    large: "",
                    loading: _vm.submit_loading,
                    disabled: _vm.submit_loading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.validate()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("vue-bottom-sheet", { ref: "fileBottomSheet" }, [
        _c(
          "section",
          {
            staticClass:
              "d-flex flex-column align-items-center justify-content-center",
          },
          [
            _c(
              "v-list",
              {
                staticClass: "w-100 pt-0 mt-auto",
                attrs: { light: "", shaped: "" },
              },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "text-center mb-1 d-block f14 font-weight-bold",
                  },
                  [_vm._v(_vm._s(_vm.$t("File selection")))]
                ),
                _c("input", {
                  ref: "fileInput",
                  attrs: { type: "file", accept: "image/*", hidden: "" },
                  on: { input: _vm.pickFile },
                }),
                _c("input", {
                  ref: "videoInput",
                  attrs: { type: "file", accept: "video/*", hidden: "" },
                  on: { input: _vm.pickVideo },
                }),
                _c(
                  "v-list-item",
                  { attrs: { ripple: "" }, on: { click: _vm.selectImage } },
                  [
                    _c(
                      "v-list-item-icon",
                      { staticClass: "my-0 align-self-center" },
                      [
                        _c(
                          "v-sheet",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-center rounded-lg p-2",
                            attrs: { color: "#fff3e1", elevation: "0" },
                          },
                          [
                            _c("iconly", {
                              attrs: {
                                type: "bulk",
                                name: "image",
                                color: "#f8b758",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item-title",
                      { staticClass: "f14", attrs: { tag: "span" } },
                      [
                        _vm.isMobile()
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("Select image from gallery"))
                              ),
                            ])
                          : _c("span", [
                              _vm._v(_vm._s(_vm.$t("Select image"))),
                            ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "v-list-item",
                  { attrs: { ripple: "" }, on: { click: _vm.selectVideo } },
                  [
                    _c(
                      "v-list-item-icon",
                      { staticClass: "my-0 align-self-center" },
                      [
                        _c(
                          "v-sheet",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-center rounded-lg p-2",
                            attrs: { color: "#ECFFF4", elevation: "0" },
                          },
                          [
                            _c("iconly", {
                              attrs: {
                                type: "bulk",
                                name: "video",
                                color: "#148B48",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item-title",
                      { staticClass: "f14", attrs: { tag: "span" } },
                      [
                        _vm.isMobile()
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("Select video from gallery"))
                              ),
                            ])
                          : _c("span", [
                              _vm._v(_vm._s(_vm.$t("Select video"))),
                            ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "my-2",
                    attrs: { block: "", color: "primary", rounded: "" },
                    on: { click: _vm.closeFileBottomSheet },
                  },
                  [_vm._v(_vm._s(_vm.$t("Close")) + " ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }